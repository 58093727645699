html,
body {
  width: 100vw;
  height: 100vh;
}

body {
  margin: 0;
  font-family: "Varta", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-blend-mode: soft-light;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
}

body.webp {
  background-image: url("images/peter-lloyd-rRWyOn9gat4-unsplash.jpg.webp");
}

body.no-webp {
  background-image: url("images/peter-lloyd-rRWyOn9gat4-unsplash.jpg");
}

body.webp.stories {
  background-image: url("images/jake-blucker-aroKbCREcPQ-unsplash.jpg.webp");
}

body.no-webp.stories {
  background-image: url("images/jake-blucker-aroKbCREcPQ-unsplash.jpg");
}

#root {
  flex-grow: 1;
}

/* source of bird animation: http://www.iamramraj.com/flying-birds-css-animation-examples/ */
*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.container {
  z-index: 1;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 10rem;
  background-blend-mode: soft-light;
  background-size: cover;
  background-position: center center;
  padding: 2rem;
}

.bird {
  background-image: url("images/bird.svg");
  background-size: auto 100%;
  width: 88px;
  height: 125px;
  will-change: background-position;
  -webkit-animation-name: fly-cycle;
  animation-name: fly-cycle;
  -webkit-animation-timing-function: steps(10);
  animation-timing-function: steps(10);
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.bird--one {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.bird--two {
  -webkit-animation-duration: 0.9s;
  animation-duration: 0.9s;
  -webkit-animation-delay: -0.75s;
  animation-delay: -0.75s;
}

.bird--three {
  -webkit-animation-duration: 1.25s;
  animation-duration: 1.25s;
  -webkit-animation-delay: -0.25s;
  animation-delay: -0.25s;
}

.bird--four {
  -webkit-animation-duration: 1.1s;
  animation-duration: 1.1s;
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.bird-container {
  position: absolute;
  top: 20%;
  left: -7.5vw;
  -webkit-transform: scale(0);
  transform: scale(0);
  will-change: transform;
  -webkit-animation-name: fly-right-one;
  animation-name: fly-right-one;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.bird-container--one {
  -webkit-animation-duration: 15s;
  animation-duration: 15s;
  -webkit-animation-delay: 0;
  animation-delay: 0;
}

.bird-container--two {
  -webkit-animation-duration: 16s;
  animation-duration: 16s;
  -webkit-animation-delay: 1s;
  animation-delay: 1s;
}

.bird-container--three {
  -webkit-animation-duration: 14.6s;
  animation-duration: 14.6s;
  -webkit-animation-delay: 9.5s;
  animation-delay: 9.5s;
}

.bird-container--four {
  -webkit-animation-duration: 16s;
  animation-duration: 16s;
  -webkit-animation-delay: 10.25s;
  animation-delay: 10.25s;
}

@-webkit-keyframes fly-cycle {
  100% {
    background-position: -900px 0;
  }
}

@keyframes fly-cycle {
  100% {
    background-position: -900px 0;
  }
}

@-webkit-keyframes fly-right-one {
  0% {
    left: -10%;
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
  }
  10% {
    left: 10%;
    -webkit-transform: translateY(2vh) scale(0.4);
    transform: translateY(2vh) scale(0.4);
  }
  20% {
    left: 30%;
    -webkit-transform: translateY(0vh) scale(0.5);
    transform: translateY(0vh) scale(0.5);
  }
  30% {
    left: 50%;
    -webkit-transform: translateY(4vh) scale(0.6);
    transform: translateY(4vh) scale(0.6);
  }
  40% {
    left: 70%;
    -webkit-transform: translateY(2vh) scale(0.6);
    transform: translateY(2vh) scale(0.6);
  }
  50% {
    left: 90%;
    -webkit-transform: translateY(0vh) scale(0.6);
    transform: translateY(0vh) scale(0.6);
  }
  60% {
    left: 110%;
    -webkit-transform: translateY(0vh) scale(0.6);
    transform: translateY(0vh) scale(0.6);
  }
  100% {
    left: 110%;
    opacity: 1;
    -webkit-transform: translateY(0vh) scale(0.6);
    transform: translateY(0vh) scale(0.6);
  }
}

@keyframes fly-right-one {
  0% {
    left: -10%;
    -webkit-transform: scale(0.3);
    transform: scale(0.3);
  }
  10% {
    left: 10%;
    -webkit-transform: translateY(2vh) scale(0.4);
    transform: translateY(2vh) scale(0.4);
  }
  20% {
    left: 30%;
    -webkit-transform: translateY(0vh) scale(0.5);
    transform: translateY(0vh) scale(0.5);
  }
  30% {
    left: 50%;
    -webkit-transform: translateY(4vh) scale(0.6);
    transform: translateY(4vh) scale(0.6);
  }
  40% {
    left: 70%;
    -webkit-transform: translateY(2vh) scale(0.6);
    transform: translateY(2vh) scale(0.6);
  }
  50% {
    left: 90%;
    -webkit-transform: translateY(0vh) scale(0.6);
    transform: translateY(0vh) scale(0.6);
  }
  60% {
    left: 110%;
    -webkit-transform: translateY(0vh) scale(0.6);
    transform: translateY(0vh) scale(0.6);
  }
  100% {
    left: 110%;
    opacity: 1;
    -webkit-transform: translateY(0vh) scale(0.6);
    transform: translateY(0vh) scale(0.6);
  }
}

@-webkit-keyframes fly-right-two {
  0% {
    left: -10%;
    opacity: 1;
    -webkit-transform: translateY(-2vh) scale(0.5);
    transform: translateY(-2vh) scale(0.5);
  }
  10% {
    left: 10%;
    -webkit-transform: translateY(0vh) scale(0.4);
    transform: translateY(0vh) scale(0.4);
  }
  20% {
    left: 30%;
    -webkit-transform: translateY(-4vh) scale(0.6);
    transform: translateY(-4vh) scale(0.6);
  }
  30% {
    left: 50%;
    -webkit-transform: translateY(1vh) scale(0.45);
    transform: translateY(1vh) scale(0.45);
  }
  40% {
    left: 70%;
    -webkit-transform: translateY(-2.5vh) scale(0.5);
    transform: translateY(-2.5vh) scale(0.5);
  }
  50% {
    left: 90%;
    -webkit-transform: translateY(0vh) scale(0.45);
    transform: translateY(0vh) scale(0.45);
  }
  51% {
    left: 110%;
    -webkit-transform: translateY(0vh) scale(0.45);
    transform: translateY(0vh) scale(0.45);
  }
  100% {
    left: 110%;
    -webkit-transform: translateY(0vh) scale(0.45);
    transform: translateY(0vh) scale(0.45);
  }
}

@keyframes fly-right-two {
  0% {
    left: -10%;
    opacity: 1;
    -webkit-transform: translateY(-2vh) scale(0.5);
    transform: translateY(-2vh) scale(0.5);
  }
  10% {
    left: 10%;
    -webkit-transform: translateY(0vh) scale(0.4);
    transform: translateY(0vh) scale(0.4);
  }
  20% {
    left: 30%;
    -webkit-transform: translateY(-4vh) scale(0.6);
    transform: translateY(-4vh) scale(0.6);
  }
  30% {
    left: 50%;
    -webkit-transform: translateY(1vh) scale(0.45);
    transform: translateY(1vh) scale(0.45);
  }
  40% {
    left: 70%;
    -webkit-transform: translateY(-2.5vh) scale(0.5);
    transform: translateY(-2.5vh) scale(0.5);
  }
  50% {
    left: 90%;
    -webkit-transform: translateY(0vh) scale(0.45);
    transform: translateY(0vh) scale(0.45);
  }
  51% {
    left: 110%;
    -webkit-transform: translateY(0vh) scale(0.45);
    transform: translateY(0vh) scale(0.45);
  }
  100% {
    left: 110%;
    -webkit-transform: translateY(0vh) scale(0.45);
    transform: translateY(0vh) scale(0.45);
  }
}

.content {
  padding: 1rem;
}

.header {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.main-picture {
  height: auto;
  width: 50vw;
  pointer-events: none;
  border-radius: 0.5rem;
  margin-top: 2rem;
}

@media (min-width: 600px) {
  .main-picture {
    width: 30vw;
  }

  .content {
    padding: 3rem;
  }
}

.header h1 {
  color: white;
  text-align: center;
}

p.main-content {
  font-weight: 600;
  color: white;
  line-height: 1.5;
  margin: 1rem 0 0 0;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.35);
  border-radius: 8px;
}

.content a {
  color: white;
}

.preview-picture {
  object-fit: cover;
  width: 100%;
  height: 400px;
}

.center-input input {
  text-align: center;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
